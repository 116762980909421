// Do not define theme colors here

@use '@angular/material' as mat;
@use './styles/themes/all' as themes;
//@use './styles/typography/config';

@include mat.core();
@include mat.core-theme(themes.$light-theme);
@include mat.all-component-themes(themes.$light-theme);

//@include mat.typography-hierarchy(config.$app-typography);

@import '../node_modules/bootstrap/scss/bootstrap';

.w-12p5 {
  width: 12.5%;
}

.w-15 {
  width: 15%;
}

.w-85 {
  width: 85%;
}

.w-87p5 {
  width: 87.5%;
}

.background-color-primary {
  background-color: mat.get-color-from-palette(themes.$primary, 900);
}

.full-width {
  width: 100%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.overflow-y {
  overflow-y: auto;
}

.content-height {
  height: calc(100vh - 128px);
}

// @media screen and (min-height: 860px) {
//   .card-full-height {
//     height: 760px;
//   }

//   .scroll-table-height {
//     height: 600px
//   }
// }

// @media screen and (max-height: 859px) {
//   .card-full-height {
//     height: 688px;
//   }

//   .scroll-table-height {
//     height: 560px
//   }
// }

.text-mat-primary {
  color: mat.get-color-from-palette(themes.$primary, 900);
}

.text-mat-accent {
  color: mat.get-color-from-palette(themes.$primary, A700);
}

.editable-column-background {
  background-color: mat.get-color-from-palette(themes.$primary, 200);
}

.disabled-column-background {
  background-color: #e5e2e0;
}

.disabled {
  color: #919191;
}

.disabled-background {
  background-color: #e5e2e0;
}

.highlight-header {
  background-color: #f5bf72 !important;
}

.highlight {
  color: #cc9f08;
}

.c {
  background-color: #e0ba6a;
}

.success {
  color: mat.get-color-from-palette(themes.$light-success);
}

.success-background {
  background-color: #daffc5;
}

.invalid {
  color: mat.get-color-from-palette(themes.$light-warn);
}

.invalid-border {
  border-color: mat.get-color-from-palette(themes.$light-warn);
}

.invalid-background {
  background-color: #ffc5c5;
}

.icon-button {
  color: mat.get-color-from-palette(themes.$primary, 900);

  &:hover {
    cursor: pointer;
    color: mat.get-color-from-palette(themes.$primary, A700);
  }
}

.icon-1-25 {
  transform: scale(1.25);
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
  min-width: 120px !important;
}

mat-tab-body {
  height: 100%;
}

.mdc-tab {
  &[aria-selected='true'] {
    background-color: #92a6c5;
    span {
      color: white !important;
    }
  }
}

li.layout-dummy {
  color: transparent; /* visually hide; could also use visibility: hidden */
}

h3 {
  font:
    500 28px 'Segoe UI',
    'Roboto',
    'sans serif' !important;
}

.fs-16 {
  font-size: 16pt;
}
